// Libs
import React from 'react';
// State
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Deps
import HeadingFullsize from '../common/heading-full-size/full-size-heading';
import SiteLink from '../common/site-link';
// Assets
import styles from './not-found.module.scss';
import locale from '../../locale/locale.json';
import pageAliases from '../../data/pageAliases';

const NotFoundComponent = ({ type }) => {
  const imgPlaceholder = useImageStaticQuery('Cannoli.png');
  const backgroundFluidImageStack = {
    md: imgPlaceholder.childImageSharp.fluid,
    lg: imgPlaceholder.lg.fluid,
    xl: imgPlaceholder.childImageSharp.fluid,
  };
  const pageData = () => {
    switch (type) {
      case 104:
        return locale.expired;
      case 403:
        return locale.notDenied;
      default:
        return locale.notFound;
    }
  };
  return (
    <div className={styles.notFoundMain}>
      <HeadingFullsize
        imageStack={backgroundFluidImageStack}
        title={pageData().pageTitle}
        subTitle={pageData().subTitle}
        description={pageData().description}
        force={true}
      >
        <>
          {type === 104 &&
          <SiteLink className={styles.button} href={pageAliases.login}>Sign In</SiteLink>}
          <div className={styles.notFoundSpacer}/>
        </>
      </HeadingFullsize>
    </div>
  );
};

export default NotFoundComponent;
