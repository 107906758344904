// Libs.
import React from 'react';
import { isString } from 'lodash';
// Assets.
import styles from './set-inner-html.module.scss';
import * as PropTypes from 'prop-types';

const SetInnerHtml = ({ data, className }) => {
  return (
    <div className={className || styles.setInnerHtml}>
      {data.header &&
      <h3
        className={styles.setInnerHtmlHeader}
      >
        {data.header}
      </h3>
      }
      {isString(data.content) ?
        <div
          className={styles.setInnerHtmlContent}
          dangerouslySetInnerHTML={{ __html: data.content }}
        /> :
        <div className={styles.setInnerHtmlContent}>
          {data.content}
        </div>
      }
    </div>
  );
};

SetInnerHtml.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    header: PropTypes.string,
    // content: PropTypes.string,
  }),
};

export default SetInnerHtml;
