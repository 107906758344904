import React, { useContext } from 'react';
import { size, get } from 'lodash';
import Img from 'gatsby-image';
import styles from './full-size-heading.module.scss';
import * as PropTypes from 'prop-types';
import SetInnerHtml from '../set-inner-html/set-inner-html';
import { AppContext } from '../../layout/AppLayout';
import { useStateValue } from '../../../store/state';

const HeadingFullsize = ({ children, imageStack, title, subTitle, secondTitle, description, force, ...domProps }) => {
  const isApp = useContext(AppContext);
  const [state] = useStateValue();
  const isLogged = get(state, 'user.access_token', false);
  const pageTitle = isLogged ? title : (subTitle ? subTitle : title);
  const imageRender = (keyName) => {
    const imageSize = imageStack[keyName] || {};
    const maxWidth = imageSize.presentationWidth || `${imageSize.width}px`;

    return (
      size(imageSize) && (
        <div key={keyName}>
          <div className={styles[`headingFullsizeImage${keyName}`]} style={{ maxWidth }}>
            <Img fluid={imageSize}/>
          </div>
        </div>
      )
    );
  };

  if (!children && (force === undefined || !force)) {
    return null;
  }


  return (
    <div {...domProps} className={styles.headingFullsizeContainerWrapper} data-app={isApp}>
      <div className={styles.headingFullsizeContainer}>
        {Object.keys(imageStack).map(imageRender)}
        <div className={styles.headingFullsizeContent}>
          <div className={styles.headingFullsizeContentContainer}>
            {title &&
            <h1 className={styles.headingFullsizeTitle}>{pageTitle}</h1>}
            {isLogged && subTitle && subTitle !== pageTitle &&
            <h2 className={styles.headingFullsizeSubtitle}>{subTitle}</h2>}
            {secondTitle && <h3 className={styles.headingFullsizeSecondTitle}>{secondTitle}</h3>}
          </div>
          {description && <div className={styles.headingFullsizeDescription}>
            <SetInnerHtml data={{ content: description }}/>
          </div>}
          {children}
        </div>
      </div>
    </div>
  );
};

HeadingFullsize.defaultProps = {
  imageStack: {
    mobile: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
  },
};

HeadingFullsize.propTypes = {
  imageStack: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  description: PropTypes.any,
  force: PropTypes.bool,
};

export default HeadingFullsize;
