import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import styles from '../components/layout/expired.module.scss';
// import styles from

import NotFoundComponent from '../components/layout/not-found'

const ExpiredPage = () => (
  <Layout>
    <SEO title="Expired Link" />
    <div className={styles.expired}>
      <NotFoundComponent type={104} />
    </div>
  </Layout>
);

export default ExpiredPage;
